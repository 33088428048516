// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  /** primary **/
  --ion-color-primary: #07335e;
  --ion-color-primary-rgb: 7, 51, 94;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #062d53;
  --ion-color-primary-tint: #20476e;

  /** secondary **/
  --ion-color-secondary: #57abd9;
  --ion-color-secondary-rgb: 87, 171, 217;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #4d96bf;
  --ion-color-secondary-tint: #68b3dd;

  /** tertiary **/
  --ion-color-tertiary: #00dcc8;
  --ion-color-tertiary-rgb: 0, 220, 200;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #00c2b0;
  --ion-color-tertiary-tint: #1ae0ce;

  /** success **/
  --ion-color-success: #38cb89;
  --ion-color-success-rgb: 56, 203, 137;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #31b379;
  --ion-color-success-tint: #4cd095;

  /** warning **/
  --ion-color-warning: #ffab00;
  --ion-color-warning-rgb: 255, 171, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e09600;
  --ion-color-warning-tint: #ffb31a;

  /** danger **/
  --ion-color-danger: #f34355;
  --ion-color-danger-rgb: 243, 67, 85;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d63b4b;
  --ion-color-danger-tint: #f45666;

  /** dark **/
  --ion-color-dark: #1a202c;
  --ion-color-dark-rgb: 26, 32, 44;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #171c27;
  --ion-color-dark-tint: #313641;

  /** medium **/
  --ion-color-medium: #a0aec0;
  --ion-color-medium-rgb: 160, 174, 192;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #8d99a9;
  --ion-color-medium-tint: #aab6c6;

  /** light **/
  --ion-color-light: #fafafa;
  --ion-color-light-rgb: 250, 250, 250;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdcdc;
  --ion-color-light-tint: #fbfbfb;

  /** extras**/
  --ion-color-extra-font:#303743;
  --ion-color-extra-medium:#CBD5E0;
  --ion-color-extra-light: #f4f7fa;
  --ion-color-extra-dark: #283041;
  --ion-color-extra-secondary: #459DF9;
  --ion-color-extra-success-tint: #d7f5e7;
  --ion-color-extra-warning-tint: #ffeecc;
  --ion-color-extra-danger-tint: #fdd9dd;
  --ion-color-extra-medium: #AFB8C3;
  --ion-color-extra-grey:#E2E8F0;
  --ion-surface-color: #F4F7FA;
  --bg-color: #e5e5e5;
  --ion-text-color: var(--ion-color-extra-dark);

  /** transparent **/
  --black-alpha-35: #00000059;

  /** fonts **/
  --ion-font-family: 'Proxima Nova', sans-serif;
  --font-size-h1: 2rem;
  --font-size-h2: 1.5rem;
  --font-size-h3: 1.25rem;
  --font-size-h4: 1.125rem;
  --font-size-h5: 1rem;
  --font-size-h6: 0.875rem;
  --font-size-small: .75rem;
  --line-height-h1: 2.5rem;
  --line-height-h2: 2rem;
  --line-height-h3: 1.75rem;
  --line-height-h4: 1.5rem;
  --line-height-h5: 1.25rem;
  --line-height-h6: 1rem;
}

/** Custom fonts **/
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/ProximaNova_Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/ProximaNova_Light.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/ProximaNova_Semibold.otf');
  font-display: swap;
}

html,
body {
  height: 100%;
}

body.mat-typography {
  margin: 0;
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  background-color: var(--bg-color);
}

body * {
  box-sizing: border-box;
}
