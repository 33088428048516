/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.header-options {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ion-chip {
    height: 1.5rem;
    --background: var(--ion-color-secondary);
    --color: var(--ion-color-light);

    ion-label {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    ion-icon {
      height: 14px;
      top: 1px;
      left: 2px;
      margin: 0px;
    }
  }

  ion-fab-button {
    width: 40px;
    height: 40px;
    z-index: 10;

    &:only-child{
      margin-left:auto;
    }

    ion-icon[name="heart"] {
      color: var(--ion-color-danger);
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(51, 41, 41, 0) 6.71%,
    rgba(10, 9, 9, 0.8) 69.67%
  );
}

.bottom-gradient {
  position: absolute;
  height: 120px;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #FFFFFF 65%, rgba(196, 196, 196, 0) 100%);
}

.divider {
  width: calc(100% - 32px);
  margin: 0 auto;
  height: 0px;
  border: 1px solid var(--ion-color-extra-grey);
}

/** Back button **/
ion-back-button {
  --color: var(--ion-color-dark);
  --background: var(--ion-color-extra-light);
  --border-radius: 50%;
  --color-focused: var(--ion-color-secondary);
  --color-hover: var(--ion-color-secondary);
}

/** Icon **/
ion-icon {
  --ionicon-stroke-width: 50px;
}

/** Button **/
ion-button {
  text-transform: none;
  --box-shadow: none;

  &:not([size="small"]):not([size="large"]) {
    height: 3rem;
    font-weight: 600;
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);
  }
}

/** Fab button **/
ion-fab-button {
  width: 48px;
  height: 48px;
  --box-shadow: none;

  &.ion-color-primary {

    &.fab-button-disabled {
      opacity: 1;
      --color: var(--ion-color-medium);
      --background: var(--ion-color-extra-grey);
      --ion-color-contrast: var(--ion-color-medium) !important;
      --ion-color-base: var(--ion-color-extra-grey) !important;
    }
  }

}

/** Input **/
ion-input[class*="ion-input"] {
  border-radius: 22px;
  margin-top: 8px;
  margin-bottom: 16px;
  line-height:var(--line-height-h4);
  border: 1px solid var(--ion-color-extra-light);
  --background: var(--ion-color-extra-light);
  --placeholder-color: var(--ion-color-extra-font);
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 16px;

  &.has-focus {
    border: 1px solid var(--ion-color-extra-secondary);
    --background: var(--ion-color-primary-contrast);
  }

  &.ion-invalid.ion-touched:not(.ion-pristine) {
    border: 1px solid var(--ion-color-danger);
    --background: var(--ion-color-primary-contrast);
  }

  .input-clear-icon {
    margin-right: 8px;
  }
}

.form-submitted {
  ion-input.ion-invalid {
    border: 1px solid var(--ion-color-danger);
    --background: var(--ion-color-primary-contrast);
  }
}

ion-label {
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
}

/** Select **/
ion-select {
  font-size: var(--font-size-h5);
  line-height:var(--line-height-h4);
  --padding-start: 8px;

  &::part(icon) {
    margin-left: 8px;
    color: var(--ion-color-extra-dark);
    content: url('/assets/icons/chevron-down.icon.svg');
  }
}

/** Searchbar **/
ion-searchbar[class*="searchbar-"].hydrated {
  padding-bottom: 0;
  --border-radius: 22px;
  --icon-color: var(--ion-color-dark);
  --placeholder-color: var(--ion-color-medium-shade);
  --background: var(--ion-color-extra-light);
  --placeholder-opacity: 0.8;

  &[class$="has-focus"] input.searchbar-input {
    border: 1px solid var(--ion-color-extra-secondary);
    --background: var(--ion-color-primary-contrast);
  }

  div.searchbar-input-container {
    height: 48px;
  }

  ion-icon.searchbar-search-icon {
    color: var(--placeholder-color);
    opacity: var(--placeholder-opacity);
    width: 16px;
    margin-left: 12px !important;
    margin-inline-start: 12px !important;
  }

  input.searchbar-input {
    -webkit-padding-start: 40px !important;
    padding-inline-start: 40px !important;
  }

  button.searchbar-clear-button[class*="searchbar-"] {
    margin-right: 8px;
  }
}

/** Checkbox **/
ion-checkbox {
  margin-inline-end: 16px;
  --border-color-checked: var(--ion-color-extra-secondary);
  --background-checked: var(--ion-color-extra-secondary);
  --border-radius: 50%;
  --size: var(--font-size-h3);
  --checkmark-width: 5px;
  margin-inline-end: 16px;
}

/** Headers **/
h1,
h2,
h3,
h4,
h5,
h6,
.title,
.subtitle {
  font-family: "Maven Pro", sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  color: var(--ion-color-extra-dark);
}

h1,
.title {
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
}

h2,
.subtitle {
  font-size: var(--font-size-h2) !important;
  line-height: var(--line-height-h2);
}

h3 {
  font-size: var(--font-size-h3) !important;
  line-height: var(--line-height-h3);
}

h4 {
  font-size: var(--font-size-h4)!important;
  line-height: var(--line-height-h4)!important;
}

h5 {
  font-size: var(--font-size-h5)!important;
  line-height: var(--line-height-h5);
}

h6 {
  font-size: var(--font-size-h6)!important;
  line-height: var(--line-height-h6);
}

.emphasis-main {
  font-size: var(--font-size-h5) !important;
  line-height: var(--line-height-h4);
  font-weight: 600 !important;
}

.emphasis {
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h5);
  font-weight: 400 !important;
}

.small {
  font-size: var(--font-size-small);
  line-height: var(--line-height-h6);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}

.price {
  display: inline-block;

  &::first-letter {
    padding-right: 0.3em;
  }
}

.expandable-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.expanded {
  display: initial;
}

/** Scrollbar **/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  border-radius: 8px;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: var(--black-alpha-35);
}

/** Autofill **/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--ion-color-light) inset !important;
  box-shadow: 0 0 0 30px var(--ion-color-light) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--ion-color-dark) !important;
}

/** ActionSheet **/
.custom-action-sheet .action-sheet-group:first-of-type,
.action-sheet-container.mat-bottom-sheet-container {
  max-height: 90vh;
  margin-top: 1rem;
  border-radius: 32px 32px 0 0;
  padding: 32px 16px 16px;
  overflow: hidden;

  &.select-date {
    padding-bottom: 110px;
  }
}

/** Grid **/
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-basis: 0;
  max-width: 100%;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &.left {
    align-items: flex-start;
  }

  &.right {
    align-items: flex-end;
  }
}

/** Maps **/
.gmnoprint {
  margin-right: .5rem;

  & + .gmnoprint,
  a, span {
    display:none;
  }
}

.gm-style {
  .gm-style-iw-c {
    border-radius: 1.375rem;
    padding: 0;
    box-shadow: none;
  }

  button {
    display: none !important;
  }
}
